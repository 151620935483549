import React from 'react';

import { HeroBanner, CaseHeader, TextContent, Gallery, GalleryLayout, Team, Testimonial } from 'modules/references';
import { sendCaseStudyPageView } from 'utils/analytics';

import Seo from '../../components/SEO';

import { ReferenceCategory } from '../../constants';

import socialPreview from '../../../static/social-previews/boxnow.png';

const CASE_ID = 'boxnow';
const MODULE_NAME = 'boxnow';
const IMAGE_PATH = `references/case-studies/${MODULE_NAME}`;

const colors = {
    bgLightGradient: 'linear-gradient(180deg, rgba(213, 222, 228, 0.6) 0%, #D5DEE4 100%);',
    bgDarkGradient: 'linear-gradient(180deg, rgba(0, 42, 128, 0.8) 0%, #002A80 100%);',
    black: '#000',
    white: '#fff',
    ivory: '#F3F3EC',
    lightGray: '#F9F9F9',
};

const team = [
    { department: 'references.case.team.management', names: ['Marek Přibáň'] },
    {
        department: 'references.case.team.design',
        names: ['Anna Meshcheryakova', 'Ondřej Bartoš', 'Filip Hudák', 'Martin Jílek'],
    },
    {
        department: 'references.case.team.ux',
        names: ['Michal Mikolaj'],
    },
    {
        department: 'references.case.team.web',
        names: [
            'Jiří Čermák',
            'Lukáš Horák',
            'Jiří Zdvomka',
            'Petr Hovorka',
            'Martin Foldyna',
            'Ivan Harašta',
            'Petr Kavulok',
        ],
    },
    {
        department: 'references.case.team.backend',
        names: [
            'Jiří Šmolík',
            'Jamal Azizov',
            'Zhanybek Sadvakassov',
            'Jakub Vala',
            'Patrik Hoffmann',
            'Dominik Veselý',
            'Lukáš Hromadník',
            'Karina Balagazova',
            'Roman Filatov',
        ],
    },
    {
        department: 'references.case.team.android',
        names: ['Jan Mottl', 'Peter Konečný', 'David Bilík', 'Michal Pešek', 'Mikhailo Liutov', 'Alisher Baibatchinov'],
    },
    {
        department: 'references.case.team.devops',
        names: ['Martin Beránek', 'Tibor Tribus', 'Tomáš Hejátko'],
    },
    {
        department: 'references.case.team.testing',
        names: ['Petr Hovorka', 'Mariya Vorona', 'Radek Bilík', 'Tomáš Samseli'],
    },
];

interface Props {}
const TravelFree = (props: Props) => (
    <>
        <Seo image={socialPreview} pageId={CASE_ID} handleMetadata={sendCaseStudyPageView} />
        <CaseHeader />
        <HeroBanner
            content={{
                projectName: `case.${CASE_ID}.hero.project`,
                projectTitle: `case.${CASE_ID}.hero.title`,
                projectYear: `case.${CASE_ID}.hero.year`,
                client: `case.${CASE_ID}.hero.client`,
                image: {
                    src: `${IMAGE_PATH}/hero_main.png`,
                    imgStyle: { objectFit: 'contain', objectPosition: '15%' },
                    style: {
                        width: '100%',
                        height: '100%',
                    },
                    alt: `case.${CASE_ID}.hero.project`,
                },
            }}
            background={colors.ivory}
            backgroundImage={{
                src: `${IMAGE_PATH}/hero_bg.png`,
                imgStyle: {
                    objectFit: 'cover',
                    objectPosition: 'center',
                },
            }}
            headerTheme="dark"
            textColor={colors.black}
        />

        <TextContent
            headerTheme="dark"
            textColor={colors.black}
            content={{
                section: `case.${CASE_ID}.section1.name`,
                title: `case.${CASE_ID}.section1.title`,
                text: `case.${CASE_ID}.section1.text`,
                category: ReferenceCategory.DELIVERY,
                outputs: [
                    'references.case.output.ux',
                    'references.case.output.design',
                    'references.case.output.web',
                    'references.case.output.android',
                    'references.case.output.backend',
                    'references.case.output.testing',
                ],
            }}
            background={colors.ivory}
        />

        {/* @ts-expect-error */}
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.EQUAL}
            fullHeight={true}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/1_hero.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'bottom right',
                            width: '100%',
                            height: '100%',
                        },
                    },
                },
            ]}
            backgroundImage={{
                src: `${IMAGE_PATH}/1_bg.png`,
                imgStyle: {
                    objectFit: 'fill',
                    objectPosition: 'center',
                },
                style: {
                    height: 'auto',
                },
            }}
            background={colors.ivory}
        />

        <TextContent
            headerTheme="dark"
            content={{
                section: `case.${CASE_ID}.section2.name`,
                title: `case.${CASE_ID}.section2.title`,
                text: `case.${CASE_ID}.section2.text`,
            }}
            background={colors.ivory}
        />

        {/* @ts-expect-error */}
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.EQUAL}
            fullHeight={true}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/2_hero.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center',
                        },
                    },
                },
            ]}
            background={colors.ivory}
        />

        <TextContent
            headerTheme="dark"
            textColor={colors.black}
            content={{
                section: `case.${CASE_ID}.section3.name`,
                title: `case.${CASE_ID}.section3.title`,
                text: `case.${CASE_ID}.section3.text`,
            }}
            background={colors.ivory}
        />

        {/* @ts-expect-error */}
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.EQUAL}
            fullHeight={true}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/3_hero.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center',
                            width: '95%',
                            padding: '0 2.5%',
                        },
                    },
                },
            ]}
            background={colors.ivory}
        />

        <TextContent
            headerTheme="dark"
            textColor={colors.black}
            content={{
                section: `case.${CASE_ID}.section4.name`,
                title: `case.${CASE_ID}.section4.title`,
                text: `case.${CASE_ID}.section4.text`,
            }}
            background={colors.ivory}
        />

        {/* @ts-expect-error */}
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.EQUAL}
            fullHeight={true}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/4-hero.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'bottom left',
                            top: '-4%',
                            left: '5%',
                        },
                    },
                },
            ]}
            backgroundImage={{
                src: `${IMAGE_PATH}/4_bg.png`,
                imgStyle: {
                    objectFit: 'fill',
                    objectPosition: 'bottom',
                },
                style: {
                    height: 'auto',
                    bottom: 0,
                },
            }}
            background={colors.ivory}
        />

        {/* @ts-expect-error */}
        <Testimonial
            headerTheme="dark"
            background={colors.lightGray}
            content={{
                quote: `case.${CASE_ID}.testimonial.text`,
                person: {
                    name: `case.${CASE_ID}.testimonial.name`,
                    role: `case.${CASE_ID}.testimonial.position`,
                    image: {
                        src: `${IMAGE_PATH}/img_person_boxnow.jpeg`,
                    },
                },
            }}
            type="PROFILE_RIGHT"
        />

        {/* @ts-expect-error */}
        <Team headerTheme="dark" content={team} background={colors.white} textColor={colors.black} />
    </>
);

export default TravelFree;
